import PAYMENT_TYPES from '../../../../constants/paymentTypes';
import extractDurationFromPeriod from '../../../../utils/extractDurationFromPeriod';

const toColorSwatchOption = (variant, index) => {
  const { content, id, vendorColorName } = variant;
  return {
    colors: { primary: content.color.primaryHexCode },
    id,
    label: vendorColorName,
    value: index.toString(),
  };
};

const sizeToConfigurationOptionItem = (size) => {
  const { enabled, id, label, sizeSplash, upfrontPrice } = size;
  return {
    isDisabled: !enabled,
    label,
    id,
    splash: sizeSplash,
    price: upfrontPrice,
    priceUnit: 'kr.',
  };
};

const installmentToConfigurationOptionItem = (installment) => {
  const { duration, id, periodAmount } = installment;
  const { months } = extractDurationFromPeriod(duration);
  return {
    duration,
    id: id || duration,
    label: `${months} mdr.`,
    price: periodAmount,
    priceUnit: 'kr./md.',
    type: PAYMENT_TYPES.INSTALLMENT,
  };
};

const mapToOptionsFormat = (options) => [
  {
    id: 0,
    items: options,
  },
];

const upfrontToConfigurationOptionItem = (upfront) => {
  const { amount, available } = upfront;
  return {
    available,
    id: amount,
    label: 'Betal kontant',
    price: amount,
    priceUnit: 'kr.',
    type: PAYMENT_TYPES.UPFRONT,
  };
};

const subscriptionToConfigurationOptionItem = (subscription) => {
  const { id, label, recurringPrice, discountText, splashText, usps, uspImage } = subscription;
  return {
    label,
    price: recurringPrice,
    priceUnit: 'kr./md.',
    id,
    finePrint: discountText,
    splash: splashText,
    usps,
    uspImage,
  };
};

// TODO: Consider rewriting this to not use param assignments if possible.
const subscriptionsToConfigurationOptionItems = (subscriptions) => {
  const groupedItems = (subscriptions ?? []).reduce((result, item) => {
    if (!result[item.groupLabel]) {
      // eslint-disable-next-line no-param-reassign
      result[item.groupLabel] = [];
    }
    result[item.groupLabel].push(subscriptionToConfigurationOptionItem(item));
    return result;
  }, {});
  return Object.entries(groupedItems).map(([groupLabel, items], index) => ({
    label: groupLabel,
    items,
    id: index,
  }));
};

export {
  toColorSwatchOption,
  sizeToConfigurationOptionItem,
  installmentToConfigurationOptionItem,
  upfrontToConfigurationOptionItem,
  mapToOptionsFormat,
  subscriptionsToConfigurationOptionItems,
};
