import PropType from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Body,
  Subhead,
  Title,
  Grid,
  Box,
  Link,
  mediumDeviceDown,
  largeDeviceUp,
  SECONDARY_BLACK,
  IconMap,
} from '@3dk/3style';
import { warn as logWarning } from '@hi3g-access/client-logger';
import MarkdownComponent from '../../../../../components/MarkdownComponent';
import { longSpecificationId } from '../ProductLongSpecification';

const classes = {
  shortSpecsHeader: {
    marginBottom: '30px',
    [mediumDeviceDown]: {
      marginBottom: '20px',
    },
  },
  shortSpecItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  borderMediumDeviceDown: (theme) => ({
    [mediumDeviceDown]: {
      borderRight: `1px solid ${theme.palette.SECONDARY_BLACK}`,
    },
  }),
  icon: (theme) => ({
    width: '100%',
    '& svg': {
      fontSize: '2.5em',
      fill: theme.palette.SECONDARY_BLACK,
      color: theme.palette.SECONDARY_BLACK,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingBottom: '10px',
    },
  }),
  shortSpecItemLabel: {
    textAlign: 'center',
    margin: '0 10px',
  },
  shortSpecsReadMore: {
    marginTop: '25px',
  },
  iconMediumDeviceDown: (theme) => ({
    [mediumDeviceDown]: {
      borderRight: `1px solid ${theme.palette.SECONDARY_BLACK}`,
    },
  }),
  iconLargeDeviceUp: (theme) => ({
    [largeDeviceUp]: {
      borderRight: `1px solid ${theme.palette.SECONDARY_BLACK}`,
    },
  }),
  headline: {
    fontWeight: '900',
  },
};

const iconHasBorder = (numIcons, iconIndex, numColumns) => {
  if (iconIndex !== 0 && (iconIndex + 1) % numColumns === 0) {
    return false;
  }

  return iconIndex < numIcons;
};

const ProductShortSpecification = ({ headline, items = [], onClick, hasTechnicalSpecifications, ...rest }) => {
  const location = useLocation();
  const longSpecificationsAnchorUrl = `${location.pathname}${location.search}#${longSpecificationId}`;

  let specificationItems = items;

  // A maximum of 4 items is supported
  if (items && items.length > 4) {
    logWarning(`Product short specification can show a maximum of 4 items, but ${items.length} was provided`);
    specificationItems = items.slice(0, 4);
  }

  const hasSpecificationItems = Array.isArray(specificationItems) && specificationItems.length > 0;
  return (
    hasSpecificationItems && (
      <>
        {headline && (
          <div css={classes.shortSpecsHeader}>
            <Box display={{ xs: 'block', lg: 'none' }}>
              <MarkdownComponent forceComponent={Subhead} forceComponentType="h2">
                {headline}
              </MarkdownComponent>
            </Box>
            <Box display={{ xs: 'none', lg: 'block' }}>
              <MarkdownComponent forceComponent={Title} forceComponentType="h2" classes={{ h2: classes.headline }}>
                {headline}
              </MarkdownComponent>
            </Box>
          </div>
        )}
        <Grid container spacing={0} css={rest.classes && rest.classes.shortSpecsWrapper}>
          {specificationItems.map((item, index) => (
            <Grid
              key={item.title}
              css={classes.shortSpecItems}
              xs={(items.length === 1 && 12) || (items.length === 2 && 6) || (items.length === 3 && 4) || 6}
              lg={3}
            >
              {item.icon && (
                <Box
                  css={[
                    classes.icon,
                    iconHasBorder(specificationItems.length, index, 3) && classes.iconMediumDeviceDown,
                    iconHasBorder(specificationItems.length, index, 4) && classes.iconLargeDeviceUp,
                  ]}
                >
                  {IconMap[item.icon]}
                </Box>
              )}
              {item.title && (
                <Body css={classes.shortSpecItemLabel} color={SECONDARY_BLACK}>
                  {item.title}
                </Body>
              )}
            </Grid>
          ))}
        </Grid>
        {hasTechnicalSpecifications && (
          <div css={classes.shortSpecsReadMore}>
            <Link
              component={RouterLink}
              replace
              to={longSpecificationsAnchorUrl}
              color={SECONDARY_BLACK}
              onClick={onClick}
            >
              Se alle specifikationer
            </Link>
          </div>
        )}
      </>
    )
  );
};

ProductShortSpecification.propTypes = {
  headline: PropType.string.isRequired,
  items: PropType.arrayOf(
    PropType.shape({
      title: PropType.string.isRequired,
      icon: PropType.node.isRequired,
    }),
  ).isRequired,
  onClick: PropType.func.isRequired,
  hasTechnicalSpecifications: PropType.bool,
};

ProductShortSpecification.defaultProps = {
  hasTechnicalSpecifications: false,
};

export default ProductShortSpecification;
