import { BridgeUpsale, Container } from '@3dk/3style';
import { error as logError } from '@hi3g-access/client-logger';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import FreeTextSplash from '../../../components/FreeTextSplash';
import PriceSplash from '../../../components/PriceSplash';
import ProductPage from '../../../components/ProductPage/ProductPage';
import URL_SEARCH_PARAMETERS from '../../../constants/urlSearchParameters';
import { ACCESSORY_BASE, PRODUCT_PAGE } from '../../../constants/urls';
import addProductToWindow from '../../../utils/barilliance/addProductToWindow';
import showUnifiedCartEvent from '../../../utils/customEvents/showUnifiedCartEvent';
import dataLayer from '../../../utils/dataLayer/dataLayer';
import { ACCESSORY_TYPE } from '../../../utils/propTypes/accessories';
import { AccessoryStructuredProductData } from '../../../utils/structuredProductData/StructuredProductData';
import ProductConfigurator from './productDetails/ProductConfigurator';
import ProductLongDescription from './productDetails/ProductLongDescription';
import ProductLongSpecification from './productDetails/ProductLongSpecification';
import ProductShortInfoComponent from './productDetails/ProductShortInfoComponent';
import generateAccessoryUrl from '../../../utils/generateAccessoryUrl';
import useProductCategorySlugs from '../../../utils/hooks/useProductCategorySlugs';
import addTrailingSlash from '../../../utils/url/addTrailingSlash';
import getStringFromSlug from '../../../utils/url/getStringFromSlug';
import FALLBACK_CATEGORY_SLUG from '../../../constants/fallBackCategorySlug';
import { homePageBreadCrumb } from '../../../components/BreadCrumbs';

const classes = {
  longDescription: (theme) => ({
    marginBottom: '16px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '25px',
    },
  }),
};

const ProductDetails = ({ accessory, setIsCartOpen, hideBreadCrumbs }) => {
  const [isBridgeOpen, setIsBridgeOpen] = useState(false);
  const [longSpecsExpanded, setLongSpecsExpanded] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const { productCategorySlugs } = useProductCategorySlugs();
  const { pathname, search } = location;
  const searchParameters = new URLSearchParams(search);
  const query = searchParameters.get(URL_SEARCH_PARAMETERS.VARIANT_QUERY_PARAMETER)?.toUpperCase();

  const longDescriptionRef = useRef(null);
  const longSpecsRef = useRef(null);

  const { name, content, variants, slug, categories } = accessory || {};

  const [selectedVariant, setSelectedVariant] = useState(
    variants && (variants.find((variant) => variant.id === query) || variants[0]),
  );
  addProductToWindow(selectedVariant.id);

  useEffect(() => {
    dataLayer.accessoryDetails(accessory, selectedVariant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant?.id]);

  const {
    shortDescriptionHeader,
    shortDescription,
    longDescription,
    technicalSpecifications,
    highlight,
    media,
    metaDataTitle,
    metaDataDescription,
    specificationHighlights,
    freeTextHighlight,
  } = content || {};

  let specs;
  try {
    specs = technicalSpecifications && JSON.parse(technicalSpecifications);
  } catch (error) {
    logError('Error parsing product technical specifications', { error });
  }

  const showCart = () => {
    const evt = new Event('showCart');
    document.dispatchEvent(evt);
    showUnifiedCartEvent();
    setIsBridgeOpen(false);
    setIsCartOpen(true);
  };

  const changeLocationBasedOnSelectedVariant = (variant) => {
    const defaultVariantChosen = variant.id === variants[0].id;

    let newPath = pathname;
    newPath += defaultVariantChosen ? '' : `?variant=${variant.id}`;

    history.replace(newPath);
  };

  const setSelectedVariantAndChangeLocation = (variant) => {
    changeLocationBasedOnSelectedVariant(variant);
    setSelectedVariant(variant);
  };

  const addedToCart = () => {
    dataLayer.addAccessoryToCart(accessory, selectedVariant);
  };

  const hideBridge = pathname.match(/^\/agent/);

  const scrollRefIntoView = (ref) => {
    scrollIntoView(ref.current, {
      behavior: 'smooth',
      scrollMode: 'always',
      block: 'start',
    }).catch((error) => logError(`Error scrolling to: ${ref.current.id}`, { error }));
  };

  useEffect(() => {
    setSelectedVariant(variants && (variants.find((variant) => variant.id === query) || variants[0]));
  }, [query, variants, accessory]);

  const handleSpecsExpand = () => {
    setLongSpecsExpanded(true);
    scrollRefIntoView(longSpecsRef);
  };

  const bridgeImage = {
    src: selectedVariant?.content?.images?.[0]?.url ?? '',
    alt: selectedVariant?.name ?? '',
  };

  const { offeringHighlight } = selectedVariant.offering || {};
  const { highlightAmount, highlightPercentage, highlightType, highlightReductionType } = offeringHighlight || {};
  const { line1, line2, line3, backgroundColour } = freeTextHighlight || {};
  const structuredProductData = AccessoryStructuredProductData(accessory, { ...selectedVariant }, location);
  const images = selectedVariant.content.images.map((image) => ({
    src: image.url,
    alt: image.description || image.title || '',
    title: image.title,
  }));

  const accessoryUrl = generateAccessoryUrl(PRODUCT_PAGE, slug, productCategorySlugs, categories);

  const categoryPageUrlSegments = accessoryUrl.split('/').filter(Boolean).slice(0, -1);
  const categoryPageUrl = categoryPageUrlSegments.join('/');
  const categoryName = getStringFromSlug(categoryPageUrlSegments.slice(-1)[0]);

  let breadCrumbs = [];
  if (!hideBreadCrumbs) {
    breadCrumbs = [
      homePageBreadCrumb,
      { name: 'Tilbehør', url: ACCESSORY_BASE },
      categoryName !== FALLBACK_CATEGORY_SLUG && { name: categoryName, url: `/${addTrailingSlash(categoryPageUrl)}` },
      { name },
    ].filter(Boolean);
  }

  return (
    <>
      <Helmet title={metaDataTitle || name}>
        {metaDataDescription && <meta name="description" content={metaDataDescription} />}
        <link rel="canonical" href={accessoryUrl} />
        {structuredProductData}
      </Helmet>
      {/* TODO(NOV-2539): Replace with triggerUpsaleBridge */}
      <BridgeUpsale isOpen={isBridgeOpen} setIsOpen={setIsBridgeOpen} image={bridgeImage} showCart={showCart} />
      <ProductPage
        backgroundColor={theme.palette.PRIMARY_LIGHT_GREY}
        images={images}
        breadCrumbs={breadCrumbs}
        Configurator={
          <ProductConfigurator
            title={name}
            subtitle={highlight}
            variants={variants}
            setIsBridgeOpen={!hideBridge ? setIsBridgeOpen : () => null}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariantAndChangeLocation}
            onAddedToCart={addedToCart}
          />
        }
        Splash={
          offeringHighlight ? (
            <PriceSplash
              reductionAmount={highlightAmount}
              reductionPercentage={highlightPercentage}
              originalPrice={selectedVariant.offering.price.originalPrice}
              type={highlightType}
              reductionType={highlightReductionType}
              salesEnd={selectedVariant.offering.salesEnd}
            />
          ) : (
            freeTextHighlight && (
              <FreeTextSplash line1={line1} line2={line2} line3={line3} backgroundColour={backgroundColour} />
            )
          )
        }
      >
        <Container>
          <ProductShortInfoComponent
            shortDescriptionHeader={shortDescriptionHeader}
            shortDescription={shortDescription}
            headline="## Specifikationer"
            shortSpecificationItems={specificationHighlights}
            media={media}
            navigateToDescription={() => scrollRefIntoView(longDescriptionRef)}
            navigateToSpecs={() => handleSpecsExpand()}
            hasTechnicalSpecifications={!!specs}
          />
          {longDescription && (
            <ProductLongDescription
              longDescriptionHeader="Beskrivelse"
              longDescription={longDescription}
              ref={longDescriptionRef}
              classes={{ root: classes.longDescription }}
            />
          )}
          {specs && (
            <ProductLongSpecification
              headline="## Specifikationer"
              technicalSpecifications={specs}
              ref={longSpecsRef}
              isExpanded={longSpecsExpanded}
              setExpanded={setLongSpecsExpanded}
            />
          )}
        </Container>
      </ProductPage>
    </>
  );
};

ProductDetails.propTypes = {
  accessory: ACCESSORY_TYPE,
  setIsCartOpen: PropTypes.func.isRequired,
  hideBreadCrumbs: PropTypes.bool,
};

ProductDetails.defaultProps = {
  accessory: {
    name: '',
    content: {},
    variants: [],
  },
  hideBreadCrumbs: false,
};

export default ProductDetails;
