import { Body } from '@3dk/3style';
import PropTypes from 'prop-types';
import useCountdown from '../../utils/hooks/useCountdownHook';

const ShippingCountdown = (props) => {
  // It is assumed that the expiration is given in local Danish time
  const { expirationHour, expirationMinute } = props;

  const countdownData = useCountdown({ dailyExpiration: { expirationHour, expirationMinute } });

  const hideCountdown = !countdownData || !countdownData.hasRemainingTime;

  if (hideCountdown) {
    return null;
  }

  return (
    <Body type="bold" {...props}>
      {`Bestil inden: ${countdownData.remainingHours}t ${countdownData.remainingMinutes}m ${countdownData.remainingSeconds}s - så sender vi i dag!`}
    </Body>
  );
};

ShippingCountdown.propTypes = {
  expirationHour: PropTypes.number.isRequired,
  expirationMinute: PropTypes.number.isRequired,
};

export default ShippingCountdown;
