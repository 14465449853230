import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, largeDeviceUp, Title, Subhead } from '@3dk/3style';
import { convertRichTextFromProductContent } from '../../../../utils/convertRichText';

const classes = {
  longDescription: {
    [largeDeviceUp]: {
      columnCount: '2',
    },
    '& > p': {
      whiteSpace: 'pre-wrap',
    },
  },
  header: {
    marginTop: '20px',
    marginBottom: '16px',
  },
};

export const longDescriptionId = 'long-description';

const ProductLongDescription = forwardRef((props, ref) => {
  const { longDescriptionHeader, longDescription, ...rest } = props;
  const longDescriptionRichText = convertRichTextFromProductContent(longDescription);

  return (
    <div id={longDescriptionId} ref={ref} css={rest.classes?.root}>
      <Grid container spacing={0}>
        {longDescriptionHeader && (
          <Grid xs={12} css={classes.header}>
            <Box display={{ xs: 'block', lg: 'none' }}>
              <Subhead type="light" component="h2">
                {longDescriptionHeader}
              </Subhead>
            </Box>
            <Box display={{ xs: 'none', lg: 'block' }}>
              <Title component="h2">{longDescriptionHeader}</Title>
            </Box>
          </Grid>
        )}
        {longDescription && (
          <Grid xs={12} css={classes.longDescription}>
            {longDescriptionRichText}
          </Grid>
        )}
      </Grid>
    </div>
  );
});

ProductLongDescription.propTypes = {
  longDescriptionHeader: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
};

export default ProductLongDescription;
