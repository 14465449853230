// Checking if its Saturday or Sunday in Denmark
const isWeekend = () => {
  const currentWeekday = new Date().toLocaleString('en-GB', {
    timeZone: 'Europe/Copenhagen',
    weekday: 'short',
  });

  return currentWeekday === 'Sat' || currentWeekday === 'Sun';
};

export default isWeekend;
