import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SpecificationLayout from '../../../../components/SpecificationLayout';

export const longSpecificationId = 'long-specification';

const ProductLongSpecification = forwardRef(
  ({ className, headline, technicalSpecifications, isExpanded, setExpanded, headerColumnWidth }, ref) => {
    if (!technicalSpecifications) return null;

    const mapTechnicalSpecifications = () =>
      Object.keys(technicalSpecifications).map((specificationSection) => ({
        header: specificationSection,
        specifications: Object.keys(technicalSpecifications[specificationSection]).map((specification) => ({
          title: specification,
          value: technicalSpecifications[specificationSection][specification],
        })),
      }));

    return (
      <SpecificationLayout
        ref={ref}
        longSpecificationId={longSpecificationId}
        headline={headline}
        className={className}
        headerColumnWidth={headerColumnWidth}
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        specificationSections={mapTechnicalSpecifications()}
      />
    );
  },
);

ProductLongSpecification.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  technicalSpecifications: PropTypes.objectOf(PropTypes.object).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  headerColumnWidth: PropTypes.oneOf(['small', 'medium']),
};

ProductLongSpecification.defaultProps = {
  className: null,
  headerColumnWidth: 'small',
};

export default ProductLongSpecification;
