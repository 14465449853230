const getLongestInstallmentOption = (variantInstallmentPlans) => {
  if (!variantInstallmentPlans?.length > 0) {
    return undefined;
  }

  const longestInstallmentOption = variantInstallmentPlans.reduce((prev, current) =>
    prev.periodAmount < current.periodAmount ? prev : current,
  );

  return longestInstallmentOption;
};

export default getLongestInstallmentOption;
