import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Grid, Link, SECONDARY_BLACK } from '@3dk/3style';
import { longDescriptionId } from '../ProductLongDescription';
import { convertRichTextFromProductContent } from '../../../../../utils/convertRichText';

const ProductShortDescription = ({ className, shortDescription, onClick }) => {
  const shortDescriptionRichText = convertRichTextFromProductContent(shortDescription);
  const location = useLocation();
  const longDescriptionAnchorUrl = `${location.pathname}${location.search}#${longDescriptionId}`;
  return (
    <>
      {shortDescription && (
        <Grid xs={12} className={className}>
          {shortDescriptionRichText}
          <Link component={RouterLink} to={longDescriptionAnchorUrl} onClick={onClick} color={SECONDARY_BLACK}>
            Læs mere
          </Link>
        </Grid>
      )}
    </>
  );
};

ProductShortDescription.propTypes = {
  className: PropTypes.string,
  shortDescription: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ProductShortDescription.defaultProps = {
  className: '',
};

export default ProductShortDescription;
