import PropTypes from 'prop-types';
import PRICE_REDUCTION_TYPES from 'constants/priceReductionTypes';
import { Body, Small, Splash, mediumDeviceUp } from '@3dk/3style';
import DISCOUNT_TYPES from '../constants/discountTypes';

const makeClasses = (props) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'repeat(auto-fit, minmax(0, 1fr))',
    alignItems: 'center',
    padding: props.hasOnlyTwoLinesOfText ? '15px 0 19px 0' : '8px 0',
    [mediumDeviceUp]: {
      padding: props.hasOnlyTwoLinesOfText ? '15px 0 24px 0' : '8px 0',
    },
  },
  smallText: {
    fontSize: '9px',
    letterSpacing: '-0.32px',
    lineHeight: '10px',
    [mediumDeviceUp]: {
      fontSize: '10px',
      letterSpacing: '-0.35px',
      lineHeight: '11px',
    },
  },
  mediumText: {
    fontSize: '15px',
    letterSpacing: '-0.53px',
    lineHeight: '21px',
    [mediumDeviceUp]: {
      fontWeight: 'bold',
      fontSize: '17px',
      letterSpacing: '-0.59px',
      lineHeight: '24px',
    },
  },
  largeText: {
    fontSize: '21px',
    letterSpacing: '-0.84px',
    lineHeight: '21px',
    [mediumDeviceUp]: {
      fontWeight: 'bold',
      fontSize: '24px',
      letterSpacing: '-0.94px',
      lineHeight: '27px',
    },
  },
  topText: {
    padding: '0 15px',
  },
  bottomText: {
    whiteSpace: 'pre-wrap',
    padding: '0 15px',
  },
});

// The price splash can have either 2 or 3 lines of text.
// This function returns true if the splash uses a layout with only 2 lines of text
function hasOnlyTwoLinesOfText(type) {
  return type === PRICE_REDUCTION_TYPES.PRICE_REDUCTION;
}

const SplashFooter = ({ originalPrice, salesEnd, type }) => {
  const classes = makeClasses({ hasOnlyTwoLinesOfText: hasOnlyTwoLinesOfText(type) });

  switch (type) {
    case PRICE_REDUCTION_TYPES.PRICE_REDUCTION:
      return null;
    case PRICE_REDUCTION_TYPES.PRICE_REDUCTION_LIMITED_NUMBER:
      return (
        <Small color="inherit" css={[classes.bottomText, classes.smallText]}>
          Begrænset{'\n'}antal
        </Small>
      );
    case PRICE_REDUCTION_TYPES.PRICE_REDUCTION_OFFERING_ENDS:
      if (salesEnd) {
        const date = new Date(salesEnd);
        return (
          <Small color="inherit" css={[classes.bottomText, classes.smallText]}>
            t.o.m{'\n'}
            {date.getDate()}/{date.getMonth() + 1}
          </Small>
        );
      }
      return null;
    case PRICE_REDUCTION_TYPES.PRICE_REDUCTION_ORIGINAL_PRICE:
      return (
        <Small color="inherit" css={[classes.bottomText, classes.smallText]}>
          Førpris{'\n'}
          {originalPrice.toLocaleString('da-DK')} kr.
        </Small>
      );
    default:
      return null;
  }
};

SplashFooter.propTypes = {
  originalPrice: PropTypes.number,
  salesEnd: PropTypes.string,
  type: PropTypes.oneOf([
    PRICE_REDUCTION_TYPES.PRICE_REDUCTION,
    PRICE_REDUCTION_TYPES.PRICE_REDUCTION_LIMITED_NUMBER,
    PRICE_REDUCTION_TYPES.PRICE_REDUCTION_ORIGINAL_PRICE,
    PRICE_REDUCTION_TYPES.PRICE_REDUCTION_OFFERING_ENDS,
  ]),
};

SplashFooter.defaultProps = {
  originalPrice: '',
  salesEnd: null,
  type: null,
};

const PriceSplash = ({
  reductionAmount,
  reductionPercentage,
  reductionType,
  originalPrice,
  salesEnd,
  type,
  ...rest
}) => {
  const classes = makeClasses({ hasOnlyTwoLinesOfText: hasOnlyTwoLinesOfText(type) });

  const getReductionText = () => {
    switch (reductionType) {
      case DISCOUNT_TYPES.AMOUNT:
        return (
          <Body color="inherit" css={classes.largeText}>
            {reductionAmount.toLocaleString('da-DK')}{' '}
            <Body display="inline" component="span" color="inherit" css={classes.mediumText}>
              kr.
            </Body>
          </Body>
        );
      case DISCOUNT_TYPES.PERCENTAGE:
        return (
          <Body color="inherit" css={classes.largeText}>
            {reductionPercentage.toLocaleString('da-DK')}
            <Body display="inline" component="span" color="inherit" css={classes.largeText}>
              %
            </Body>
          </Body>
        );
      default:
        return null;
    }
  };

  if (type) {
    return (
      <Splash css={classes.root} {...rest}>
        <Body color="inherit" css={[classes.topText, classes.mediumText]}>
          Spar
        </Body>
        {getReductionText()}
        <SplashFooter originalPrice={originalPrice} salesEnd={salesEnd} type={type} />
      </Splash>
    );
  }
  return null;
};

PriceSplash.propTypes = {
  reductionAmount: PropTypes.number,
  reductionPercentage: PropTypes.number,
  originalPrice: PropTypes.number,
  salesEnd: PropTypes.string,
  type: PropTypes.oneOf([
    PRICE_REDUCTION_TYPES.PRICE_REDUCTION,
    PRICE_REDUCTION_TYPES.PRICE_REDUCTION_LIMITED_NUMBER,
    PRICE_REDUCTION_TYPES.PRICE_REDUCTION_ORIGINAL_PRICE,
    PRICE_REDUCTION_TYPES.PRICE_REDUCTION_OFFERING_ENDS,
  ]),
  reductionType: PropTypes.oneOf([DISCOUNT_TYPES.AMOUNT, DISCOUNT_TYPES.PERCENTAGE]),
};

PriceSplash.defaultProps = {
  reductionAmount: 0,
  reductionPercentage: 0,
  originalPrice: 0,
  salesEnd: null,
  type: null,
  reductionType: null,
};

export default PriceSplash;
