// This list was compiled using the following api: https://api.dryg.net/
// We filtered on the condition that a day had "arbetsfri dag" === "Ja".
// We also excluded every Saturday and Sunday to make the list smaller,
// since we already have a check for that in the "isWeekend" util.
// For this reason, some years might miss an obvious holiday like Christmas if it's a Saturday e.g.

const swedishHolidays = [
  '2023-01-06',
  '2023-04-07',
  '2023-04-10',
  '2023-05-01',
  '2023-05-18',
  '2023-06-06',
  '2023-06-23',
  '2023-12-25',
  '2023-12-26',
  '2024-01-01',
  '2024-03-29',
  '2024-04-01',
  '2024-05-01',
  '2024-05-09',
  '2024-06-06',
  '2024-06-21',
  '2024-12-24',
  '2024-12-25',
  '2024-12-26',
  '2024-12-31',
  '2025-01-01',
  '2025-01-06',
  '2025-04-18',
  '2025-04-21',
  '2025-05-01',
  '2025-05-29',
  '2025-06-06',
  '2025-06-20',
  '2025-12-24',
  '2025-12-25',
  '2025-12-26',
  '2025-12-31',
  '2026-01-01',
  '2026-01-06',
  '2026-04-03',
  '2026-04-06',
  '2026-05-01',
  '2026-05-14',
  '2026-06-19',
  '2026-12-24',
  '2026-12-25',
  '2026-12-31',
  '2027-01-01',
  '2027-01-06',
  '2027-03-26',
  '2027-03-29',
  '2027-05-06',
  '2027-06-25',
  '2027-12-24',
  '2027-12-31',
  '2028-01-06',
  '2028-04-14',
  '2028-04-17',
  '2028-05-01',
  '2028-05-25',
  '2028-06-06',
  '2028-06-23',
  '2028-12-25',
  '2028-12-26',
];

const isSwedishHoliday = () => {
  const currentDate = new Date();

  // This locale is used to get the yyyy-mm-dd format
  const currentDanishDate = currentDate.toLocaleString('sv-SE', {
    timeZone: 'Europe/Copenhagen',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return swedishHolidays.includes(currentDanishDate);
};

export default isSwedishHoliday;
