import { Box, Grid, Subhead, Title } from '@3dk/3style';
import PropTypes from 'prop-types';
import Imgix, { Picture, Source } from 'react-imgix';
import MarkdownComponent from '../../../../components/MarkdownComponent';
import { MEDIA_TYPE } from '../../../../utils/propTypes/accessories';
import ProductShortDescription from './productShortInfoComponent/ProductShortDescription';
import ProductShortSpecification from './productShortInfoComponent/ProductShortSpecification';

const makeClasses = (props) => ({
  image: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '12px',
      marginBottom: '20px',
    },
  }),
  video: (theme) => ({
    width: '478px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  headlineWrapper: (theme) => ({
    marginBottom: props.shortDescriptionHeader && '16px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: props.shortDescriptionHeader && '20px',
    },
  }),
  shortDescriptionSpacing: (theme) => ({
    marginBottom: '55px',
    marginTop: '5px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '20px',
    },
  }),
  descriptionHeader: {
    fontWeight: '900',
  },
});

const ProductShortInfoComponent = ({
  shortDescriptionHeader,
  shortDescription,
  headline,
  shortSpecificationItems,
  navigateToDescription,
  media,
  navigateToSpecs,
  hasTechnicalSpecifications,
}) => {
  const classes = makeClasses({ shortDescriptionHeader });

  // Additional check because empty object returns true
  const isMedia = media && Object.keys(media).length > 0;
  const isVideo = isMedia && media.type.match('video/mp4');

  const commonProps = {
    src: isMedia && media.url,
    sizes: `(min-width: 1024px) 478px, 100vw`, // Media query corresponds to small device down breakpoint
    // TODO: is this the correct image property to use for alt?
    alt: isMedia && media.description,
  };

  return (
    <Grid container spacing={0}>
      {shortDescriptionHeader && (
        <Grid xs={12} lg={12}>
          {/* The headline should at max fill half the page (in lg), but picture below
          should not be on line with header */}
          <Grid xs={12} lg={6}>
            {/* Headline */}
            <Grid xs={12} css={classes.headlineWrapper}>
              <Box display={{ xs: 'block', lg: 'none' }}>
                <MarkdownComponent forceComponent={Subhead} forceComponentType="h2">
                  {shortDescriptionHeader}
                </MarkdownComponent>
              </Box>
              <Box display={{ xs: 'none', lg: 'block' }}>
                <MarkdownComponent
                  forceComponent={Title}
                  forceComponentType="h2"
                  classes={{ span: classes.descriptionHeader }}
                >
                  {shortDescriptionHeader}
                </MarkdownComponent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid xs={12} lg={6}>
        <ProductShortDescription
          shortDescription={shortDescription}
          onClick={navigateToDescription}
          css={classes.shortDescriptionSpacing}
        />
        <ProductShortSpecification
          headline={headline}
          items={shortSpecificationItems}
          onClick={navigateToSpecs}
          hasTechnicalSpecifications={hasTechnicalSpecifications}
        />
      </Grid>
      <Grid css={classes.image} xs={12} lg={6}>
        {isMedia &&
          (isVideo ? (
            <video autoPlay loop muted src={media.url} css={classes.video} />
          ) : (
            <Picture>
              <Source {...commonProps} imgixParams={{ fm: 'webp' }} htmlAttributes={{ type: 'image/webp' }} />
              <Imgix {...commonProps} css={classes.image} />
            </Picture>
          ))}
      </Grid>
    </Grid>
  );
};

ProductShortInfoComponent.propTypes = {
  shortDescriptionHeader: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  shortSpecificationItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    }),
  ).isRequired,
  media: MEDIA_TYPE,
  navigateToDescription: PropTypes.func.isRequired,
  navigateToSpecs: PropTypes.func.isRequired,
  hasTechnicalSpecifications: PropTypes.bool,
};

ProductShortInfoComponent.defaultProps = {
  media: {},
  hasTechnicalSpecifications: false,
};

export default ProductShortInfoComponent;
