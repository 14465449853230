const sizeToConfigurationOptionItem = (sizes) => [
  {
    id: 0,
    items: sizes?.map((size) => ({
      isDisabled: false,
      label: size.sizeName,
      id: size.variantId,
    })),
  },
];

export default sizeToConfigurationOptionItem;
