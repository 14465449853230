import { buildRoute } from 'constants/urls';
import FALLBACK_CATEGORY_SLUG from 'constants/fallBackCategorySlug';

const generateAccessoryUrl = (base, slug, productCategorySlugs, categories = [], variantId = null) => {
  const categorySlug =
    categories && categories.length > 0
      ? productCategorySlugs[categories[0]] ?? FALLBACK_CATEGORY_SLUG
      : FALLBACK_CATEGORY_SLUG;
  const route = `${buildRoute(base, categorySlug, slug)}${variantId ? `?variant=${variantId}` : ''}`;
  return route;
};

export default generateAccessoryUrl;
