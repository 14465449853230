export default function getStringFromSlug(str) {
  // be aware that some danish words can contain 'ae', 'aa', etc.
  // so don't use this if you expect words like these to occur
  return (
    str
      // Replace 'aa' with 'å' using a case-insensitive regular expression.
      .replace(/aa/gi, 'å')
      // Replace 'ae' with 'æ' using a case-insensitive regular expression.
      .replace(/ae/gi, 'æ')
      // Replace 'oe' with 'ø' using a case-insensitive regular expression.
      .replace(/oe/gi, 'ø')
      // Replace one or more non-word characters (all characters not a-z, A-Z, 0-9) or underscores with a hyphen.
      .replace(/[-_]/gi, ' ')
      // Convert all characters to lower case.
      .toLowerCase()
  );
}
