import React from 'react';
import runtimeConfig from '../config';
import safeParse from '../safe-json';

const { BASE_URI } = runtimeConfig;

const StructuredProductData = (productData) => {
  const { eanCode, name, variant, description, price, brand, specificationHighlights, size, weight, location } =
    productData;

  const imageUrl = variant?.content?.images?.[0]?.url ?? '';

  const positiveNotes = specificationHighlights
    ?.filter((spec) => Object.keys(spec).length > 0)
    .map((spec, index) => {
      const img = spec.iconUrl ? `, "image": "${spec.iconUrl}"` : '';
      return `{"@type": "ListItem", "position": "${index}", "name": "${spec.title}"${img}}`;
    });

  let weightText = `""`; // weightText should be an empty string in order for the weight attribute to get excluded
  if (weight) {
    const [value, unit] = weight.split(' ');

    if (value && unit) {
      weightText = `{
        "@type": "QuantitativeValue",
        "value": "${value}",
        "unitText": "${unit}"
      }`;
    }
  }
  const { pathname, search } = location;
  // The search term allows for user-input. To avoid Cros Site Scripting (XSS) we encode it.
  const variantUrl = encodeURI(`${BASE_URI}${pathname}${search}`);

  const obj = `{
        "@context": "https://schema.org/",
        "@type": "Product",
        "@id": "${variantUrl}",
        "url": "${variantUrl}",
        "gtin": "${eanCode}",
        "name": "${name}",
        "description": "${description}",
        "image": "${imageUrl}",
        "color": "${variant?.vendorColorName ?? variant?.vendorColourName}",
        "size": "${size ?? ''}",
        "brand": {
          "@type": "Brand",
          "name": "${brand}"
        },
        "weight": ${weightText},
        "itemCondition": "NewCondition",
        "offers": {
          "@type": "Offer",
          "availability": "https://schema.org/InStock",
          "price": "${price}",
          "priceCurrency": "DKK",
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "DKK"
            }
          },
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnFees": "FreeReturn"
          }
        },
        "positiveNotes": {
          "@type": "ItemList",
          "itemListElement": [${positiveNotes}]
        }
      }`;

  return <script type="application/ld+json">{obj}</script>;
};

export const DeviceStructuredProductData = (device, location) => {
  const {
    eanCode,
    name,
    detailedVariant,
    seo: { description },
    price: { deviceTotalDiscountedPrice: price },
    brand,
    content: { specificationHighlights, technicalSpecificationsJson },
  } = device;

  if (!technicalSpecificationsJson) {
    return null;
  }

  let technicalSpecifications;
  try {
    technicalSpecifications = safeParse(technicalSpecificationsJson);
  } catch (e) {
    return null;
  }

  const size = technicalSpecifications?.hardware?.størrelse ?? technicalSpecifications?.general?.størrelse;
  const weight = technicalSpecifications?.hardware?.vægt ?? technicalSpecifications?.general?.vægt;

  return StructuredProductData({
    eanCode,
    name,
    variant: detailedVariant,
    description,
    price,
    brand,
    specificationHighlights,
    size,
    weight,
    location,
  });
};

export const AccessoryStructuredProductData = (accessory, variant, location) => {
  const {
    name,
    brand,
    content: { specificationHighlights, metaDataDescription },
  } = accessory;

  const {
    eanCode,
    offering: {
      price: { sellingPrice: price },
    },
  } = variant;

  return StructuredProductData({
    eanCode,
    name,
    variant,
    description: metaDataDescription,
    price,
    brand,
    specificationHighlights,
    location,
  });
};
