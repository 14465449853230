import { gql } from '@apollo/client';

const getCategoryToSlugMapQuery = gql`
  query GetCategoryToSlugMap {
    categoryToSlugMap {
      category
      slug
      id
    }
  }
`;

export default getCategoryToSlugMapQuery;
