import { ColorSwatch, ConfigurationOption, SECONDARY_BLACK, Small } from '@3dk/3style';
import { error as logError } from '@hi3g-access/client-logger';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import useCart from 'utils/hooks/useCartHook';
import { installmentToConfigurationOptionItem } from 'app/routes/PreDcDevicePageContainer/preDcDevicePage/mappers';
import Configurator from '../../../../components/ProductPage/Configurator';
import PriceSummary from '../../../../components/ProductPage/PriceSummary';
import MENU_Z_INDEX_THRESHOLD from '../../../../constants/menuZIndexThreshold';
import PAYMENT_TYPES from '../../../../constants/paymentTypes';
import getLongestInstallmentOption from '../../../../utils/accessoryHelpers/installmentOptions';
import { ACCESSORY_VARIANT_TYPE } from '../../../../utils/propTypes/accessories';
import getColorOptions from './utils/getColorOptions';
import paymentOptionsToConfigurationOptionItem from './utils/paymentOptionsToConfigurationOptionItem';
import sizeToConfigurationOptionItem from './utils/sizeToConfigurationOptionItem';
import ShippingCountdownContainer from '../../../../components/ShippingCountdownContainer';
import STOCK_LEVEL from '../../../../constants/stockLevel';

const makeClasses = () => ({
  configurationOption: {
    marginBottom: '10px',
    padding: '0 10px',
    flexGrow: '0',
  },
  installmentCaption: {
    marginTop: '10px',
    fontStyle: 'italic',
  },
  drawer: (theme) => ({
    '& .MuiDrawer-paper': {
      paddingTop: '75px',
      width: 'min(425px, 100%)',
      [theme.breakpoints.down('md')]: {
        paddingBottom: '100px',
        width: '100%',
      },
    },
    '&.MuiDrawer-root': {
      zIndex: MENU_Z_INDEX_THRESHOLD,
    },
  }),
  colorSwatch: {
    marginBottom: '15px',
  },
  paymentOption: {
    marginBottom: '10px',
  },
  shippingCountdown: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
  },
});

const ProductConfigurator = (props) => {
  const { title, subtitle, variants, setIsBridgeOpen, selectedVariant, setSelectedVariant, onAddedToCart } = props;

  const classes = makeClasses();
  const { addToCart, addToCartLoading, addToCartError } = useCart(() => setIsBridgeOpen(true));

  const {
    offering: selectedVariantOffer,
    expectedDelivery,
    vendorColourName: colorLabel,
    stockLevel,
  } = selectedVariant || {};

  const { id: offeringId, price, paymentPlanOptions } = selectedVariantOffer || {};

  // Installment payment option type
  const longestInstallmentPeriod = getLongestInstallmentOption(paymentPlanOptions.installmentPlans);

  const hasInstallments = paymentPlanOptions.installmentPlans.length > 0;
  const initialSelectedPaymentType = hasInstallments ? PAYMENT_TYPES.INSTALLMENT : PAYMENT_TYPES.UPFRONT;
  const [selectedPaymentType, setSelectedPaymentType] = useState(initialSelectedPaymentType);
  const [selectedInstallmentDuration, setSelectedInstallmentDuration] = useState(longestInstallmentPeriod?.duration);
  const [prices, setPrices] = useState({ upfrontPrice: 0, installmentPrice: 0 });

  const paymentOptionsConfigurationOptions = paymentOptionsToConfigurationOptionItem(paymentPlanOptions);
  const paymentOptions = paymentOptionsConfigurationOptions[0].items;

  const initialSelectedPaymentOption = hasInstallments
    ? paymentOptions.find((paymentOption) => paymentOption.duration === longestInstallmentPeriod.duration)
    : paymentOptions.find((paymentOption) => paymentOption.type === PAYMENT_TYPES.UPFRONT);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(initialSelectedPaymentOption);

  const colorOptions = getColorOptions(variants);

  const [selectedColor, setSelectedColor] = useState(
    colorOptions.find((colorOption) => colorOption.value === selectedVariant.vendorColourName),
  );

  const [sizeOptions, setSizeOptions] = useState(sizeToConfigurationOptionItem(selectedColor.sizes));

  // Find corresponding installment option when changing variants, otherwise use longest period
  const onVariantChange = () => {
    const upfrontOption = paymentOptions.find((paymentOption) => paymentOption.type === PAYMENT_TYPES.UPFRONT);
    const mappedLongestInstallmentPeriod =
      longestInstallmentPeriod && installmentToConfigurationOptionItem(longestInstallmentPeriod);

    const updatePaymentTypeAndPrices = ({ paymentType = selectedPaymentType, paymentOption = upfrontOption }) => {
      setSelectedPaymentType(paymentType);
      setSelectedPaymentOption(paymentOption);
      setPrices({
        installmentPrice: paymentType === PAYMENT_TYPES.INSTALLMENT ? paymentOption.price : 0,
        upfrontPrice: paymentType === PAYMENT_TYPES.INSTALLMENT ? 0 : price.sellingPrice,
      });
    };

    if (!hasInstallments) {
      // No installments, change to upfront
      updatePaymentTypeAndPrices({ paymentType: PAYMENT_TYPES.UPFRONT });
    } else {
      // Check for installment with same duration
      const installmentOptionWithSamePeriod = paymentOptions.find(
        (installmentPlan) => installmentPlan.duration === selectedInstallmentDuration,
      );
      // If there is installment with same duration, let that be the new payment option
      const newPaymentOption = installmentOptionWithSamePeriod || mappedLongestInstallmentPeriod;
      updatePaymentTypeAndPrices(
        selectedPaymentType === PAYMENT_TYPES.UPFRONT ? {} : { paymentOption: newPaymentOption },
      );
      setSelectedInstallmentDuration(newPaymentOption.id);
    }
  };

  // Trigger payment option selection when switching variant
  useEffect(() => {
    onVariantChange();
  }, [selectedVariant]);

  if (!selectedVariant) return null;

  if (addToCartError) {
    // Error object will persist in state also when user clicks interact with other components on the product page.
    // Thus this error message will keep popping up until state has been reset.
    alert('Du kan ikke lægge noget i kurven lige nu.\nPrøv at opdater siden mens vi undersøger sagen.');
  }

  const handleColorChange = (variantColor) => {
    const selectedColorOption = colorOptions.find((color) => color.value === variantColor);
    const variantColorHasNoSizes = selectedColorOption.sizes.length === 0;

    let variantIdToChangeTo;

    if (variantColorHasNoSizes) {
      const variantWithMatchingColor = variants.find((variant) => variant.vendorColourName === variantColor);
      variantIdToChangeTo = variantWithMatchingColor.id;
    } else {
      const currentSizeExistsForNewVariant = selectedColorOption.sizes
        .map((size) => size.sizeName)
        .includes(selectedVariant.size);
      const selectedSizeOption = currentSizeExistsForNewVariant
        ? selectedColorOption.sizes.filter((size) => size.sizeName === selectedVariant.size)[0]
        : selectedColorOption.sizes[0];
      variantIdToChangeTo = selectedSizeOption.variantId;
    }

    const newSelectedVariant = variants.find((variant) => variant.id === variantIdToChangeTo);
    setSelectedVariant(newSelectedVariant);

    const newSelectedColor = colorOptions.find(
      (colorOption) => colorOption?.value === newSelectedVariant?.vendorColourName,
    );
    setSelectedColor(newSelectedColor);

    setSizeOptions(sizeToConfigurationOptionItem(newSelectedColor.sizes));
  };

  const handleSizeChange = (item) => {
    const newSelectedVariant = variants.find((variant) => variant.id === item.id);
    setSelectedVariant(newSelectedVariant);
  };

  const handlePaymentOptionChange = (item) => {
    setSelectedPaymentOption(item);
    setSelectedPaymentType(item.type);
    if (item.type === PAYMENT_TYPES.INSTALLMENT) {
      setSelectedInstallmentDuration(item.id);
    }
  };

  const variantHasMultipleSizes = selectedColor.sizes.length > 0;
  const selectedColorIndex = colorOptions.findIndex((colorOption) => colorOption.value === selectedColor.value);
  const initialOptionIndex = selectedColorIndex > -1 && selectedColorIndex;

  const handleAddToCart = () => {
    const request = {
      variables: {
        offering: {
          offeringId,
          paymentPlanType: selectedPaymentType,
          installmentDuration: selectedPaymentType === PAYMENT_TYPES.INSTALLMENT ? selectedInstallmentDuration : null,
        },
      },
    };

    addToCart(request)
      .then(onAddedToCart)
      .catch((e) => logError('Error occurred while adding product to cart', { error: e }));
  };
  return (
    <Configurator
      delivery={expectedDelivery}
      isAddToCartButtonDisabled={selectedPaymentOption?.available === false}
      isAddToCartButtonLoading={addToCartLoading}
      onAddToCartButtonClick={() => handleAddToCart()}
      subtitle={subtitle}
      title={title}
    >
      {colorOptions && (
        <div css={classes.colorSwatch}>
          <ColorSwatch
            selectorOf="Farve"
            label={colorLabel || 'Ingen'}
            options={colorOptions}
            onChange={handleColorChange}
            initialOptionIndex={initialOptionIndex}
          />
        </div>
      )}
      {variantHasMultipleSizes && (
        <ConfigurationOption
          css={classes.configurationOption}
          drawerProps={{ css: classes.drawer }}
          buttonLabel="Størrelse"
          drawerLabel="Vælg størrelse"
          onChange={(item) => handleSizeChange(item)}
          drawerItems={sizeOptions}
          selectedId={{ id: selectedVariant.id, group: 0 }}
        />
      )}
      {hasInstallments && (
        <div css={classes.paymentOption}>
          <ConfigurationOption
            css={classes.configurationOption}
            drawerProps={{ css: classes.drawer }}
            buttonLabel={t('Product_Page.Configurator.Payment_Button_Label')}
            drawerLabel={t('Product_Page.Configurator.Payment_Drawer_Label')}
            onChange={(item) => handlePaymentOptionChange(item)}
            drawerItems={paymentOptionsConfigurationOptions}
            selectedId={{ id: selectedPaymentOption?.id, group: 0 }}
          />
          <Small css={classes.installmentCaption} color={SECONDARY_BLACK}>
            {t('Product_Page.Configurator.Buy_Installment_Help_Text')}
          </Small>
        </div>
      )}
      <PriceSummary installment={prices.installmentPrice} upfront={prices.upfrontPrice} />
      {stockLevel === STOCK_LEVEL.IN_STOCK && <ShippingCountdownContainer css={classes.shippingCountdown} />}
    </Configurator>
  );
};

ProductConfigurator.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  variants: PropTypes.arrayOf(ACCESSORY_VARIANT_TYPE).isRequired,
  setIsBridgeOpen: PropTypes.func.isRequired,
  selectedVariant: ACCESSORY_VARIANT_TYPE.isRequired,
  setSelectedVariant: PropTypes.func.isRequired,
  onAddedToCart: PropTypes.func.isRequired,
};

ProductConfigurator.defaultProps = {
  title: null,
  subtitle: null,
};

export default ProductConfigurator;
