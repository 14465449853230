import { useQuery } from '@apollo/client';
import getCategoryToSlugMapQuery from '../../apollo/queries/getCategoryToUrlMap';
import convertCategoryToUrlMap from '../../server/utils/convertCategoryToUrlMap';

const useProductCategorySlugs = () => {
  const { data: categoryToSlugMapData, loading } = useQuery(getCategoryToSlugMapQuery);

  if (loading) {
    return { productCategorySlugs: [] };
  }

  const productCategorySlugs = convertCategoryToUrlMap(categoryToSlugMapData);

  return { productCategorySlugs };
};

export default useProductCategorySlugs;
