import {
  installmentToConfigurationOptionItem,
  mapToOptionsFormat,
  upfrontToConfigurationOptionItem,
} from '../../../PreDcDevicePageContainer/preDcDevicePage/mappers';

const paymentOptionsToConfigurationOptionItem = (paymentPlanOptions) => {
  const { installmentPlans, upfrontPlan } = paymentPlanOptions || {};
  const itemsFromInstallmentPlans = (installmentPlans || [])
    .map(installmentToConfigurationOptionItem)
    .sort((a, b) => (a.price || 0) - (b.price || 0));

  return mapToOptionsFormat([...itemsFromInstallmentPlans, upfrontToConfigurationOptionItem(upfrontPlan)]);
};

export default paymentOptionsToConfigurationOptionItem;
