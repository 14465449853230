/**
 * This is used by third party company (Barilliance) to do lead generation. It is used to match product pages with
 * offers in cart. Therefore, it should be available on product pages and match the id given in on the window.cart
 * object.
 */
const addProductToWindow = (id) => {
  if (typeof window === 'undefined') return;

  window.product = { psid: id };
};

export default addProductToWindow;
