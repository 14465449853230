const getColorOptions = (variants) => {
  const sizeOrder = ['OneSize', 'Small', 'Small/Medium', 'Medium', 'Medium/Large', 'Large'];

  const colorOptionsMap = variants
    ? variants.reduce((acc, variant) => {
        const colorKey = variant.vendorColourName;
        if (!acc[colorKey]) {
          acc[colorKey] = {
            colors: {
              primary: variant.content?.colour?.primaryHexCode,
              secondary: variant.content?.colour?.secondaryHexCode,
            },
            value: colorKey,
            sizes: [],
          };
        }
        if (variant.size && !acc[colorKey].sizes.find((sizeObj) => sizeObj.sizeName === variant.size)) {
          acc[colorKey].sizes.push({ sizeName: variant.size, variantId: variant.id });
        }
        acc[colorKey].sizes.sort((a, b) => sizeOrder.indexOf(a.sizeName) - sizeOrder.indexOf(b.sizeName));
        return acc;
      }, {})
    : {};

  const colorOptionsArray = Object.values(colorOptionsMap);
  return variants ? colorOptionsArray : [];
};

module.exports = getColorOptions;
