import isWeekend from '../utils/dateHelpers/isWeekend';
import isSwedishHoliday from '../utils/dateHelpers/IsSwedishHoliday';
import FEATURE_FLAG from '../utils/feature-flags';
import ShippingCountdown from './shippingCountdownContainer/ShippingCountdown';
import { useFeatureFlagContext } from '../optimizely';

const ShippingCountdownContainer = (props) => {
  const { getFeatureFlag } = useFeatureFlagContext();
  const enableShippingCountdown = getFeatureFlag(FEATURE_FLAG.SHIPPING_COUNTDOWN, false);

  const hideShippingCountdown = isWeekend() || isSwedishHoliday() || !enableShippingCountdown;
  if (hideShippingCountdown) {
    return null;
  }

  return <ShippingCountdown expirationHour={14} expirationMinute={30} {...props} />;
};

export default ShippingCountdownContainer;
